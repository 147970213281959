import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createHttpLink } from "@apollo/client";
import { isNonEmptyArray } from "@apollo/client/utilities";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_SERVER_ENDPOINT,
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
  //defaultOptions: defaultOptions,
});

export default client;
