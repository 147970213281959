import { Fragment, useEffect, useState } from "react";
import styles from "./ItemSelection.module.css";
import RadioButton from "../../UI/RadioButton";

import SelectionLabel from "../../UI/SelectionLabel";
import SizeItem from "../../UI/SizeItem";
import LoadingAnimation from "../../UI/LoadingAnimation";
import useColorManagment from "../../hooks/use-color-managment";
import useGetItemVariations from "../../hooks/use-get-item-variations";
import ColorSelection from "./ColorSelection";

const ItemSelection = (props) => {
  const colors = props.color;
  const newColors = props.newColors;
  const sizes = props.size;
  const [variations, variationsLoading, variationsError] = useGetItemVariations(
    props.itemSlug
  );

  const modifiedColors = useColorManagment(colors);

  const makeColorSelections = () => {
    let colorNames = newColors.find((el) => (el = el.name === "color_names"));

    const colorSectionsData = newColors.filter(
      (el) => (el = el.name.includes("pa_color"))
    );

    if (colorNames) {
      const colorSections = [
        ...colorNames.options.map((name, index) => {
          return {
            title: name,
            ...colorSectionsData[index],
          };
        }),
      ];

      return colorSections;
    } else {
      return [
        {
          title: "boju:",
          ...colorSectionsData[0],
        },
      ];
    }
  };

  const [multiColorSections, setMultiColorSections] = useState(
    makeColorSelections()
  );

  useEffect(() => {
    if (multiColorSections) {
      props.setNewSelectedColorIndex(
        multiColorSections.map((el) => {
          return {
            title: el.title,
            name: el.name,
            index: 0,
            option: el.options[0],
            term: el.terms.nodes[0].name,
          };
        })
      );
    }
  }, [multiColorSections]);

  useEffect(() => {
    console.log(variations);
  }, [variations]);

  return (
    <Fragment>
      <div className={styles[`wrapper`]}>
        <div className={styles[`selectors`]}>
          {props.newSelectedColorIndex && (
            <>
              {!multiColorSections ? (
                <ColorSelection
                  modifiedColors={modifiedColors}
                  selectedColorIndex={props.selectedColorIndex}
                  newSelectedColorIndex={props.newSelectedColorIndex}
                  setNewSelectedColorIndex={props.setNewSelectedColorIndex}
                  setColorIndex={props.setColorIndex}
                />
              ) : (
                <>
                  {multiColorSections.map((color, index) => {
                    return (
                      <ColorSelection
                        key={index}
                        colorInfo={color}
                        colorIndex={index}
                        selectedColorIndex={props.selectedColorIndex}
                        setNewSelectedColorIndex={
                          props.setNewSelectedColorIndex
                        }
                        newSelectedColorIndex={props.newSelectedColorIndex}
                        setColorIndex={props.setColorIndex}
                      />
                    );
                  })}
                </>
              )}
            </>
          )}

          <div className={styles[`size-selector`]}>
            <SelectionLabel text="Odaberi veličine" />
            {!variationsLoading &&
            props.newSelectedColorIndex &&
            variations !== undefined &&
            modifiedColors.colorSlugs ? (
              <div className={styles[`size-list`]}>
                {variations.toReversed().map((vari, i) => {
                  if (
                    props.newSelectedColorIndex.every((selectedColor) => {
                      const colorInVariation = vari.attributes.nodes.find(
                        (el) => el.name === selectedColor.name
                      );
                      return (
                        colorInVariation &&
                        colorInVariation.value === selectedColor.option
                      );
                    })
                  ) {
                    return (
                      <SizeItem
                        key={i}
                        size={
                          sizes.terms.nodes[
                            sizes.options.findIndex(
                              (sz) =>
                                sz ===
                                vari.attributes.nodes.filter(
                                  (el) => el.name === "pa_size"
                                )[0].value
                            )
                          ].name
                        }
                        price={0}
                        index={i}
                        forBasket={props.forBasket}
                        setForBasket={props.setForBasket}
                        variations={variations}
                        custom={props.customSize}
                        color={{
                          slug: modifiedColors.colorSlugs[props.colorIndex],
                          name: modifiedColors.colorNames[props.colorIndex],
                          hash: modifiedColors.colorHashes[props.colorIndex],
                        }}
                      />
                    );
                  }
                })}
              </div>
            ) : (
              <div className={styles["loading-wrapper"]}>
                <LoadingAnimation />
              </div>
            )}
          </div>
          {/*<div className={styles[`size-selector`]}>
            <SelectionLabel text="Odaberi veličine" />
            {!variationsLoading &&
            variations !== undefined &&
            modifiedColors.colorSlugs ? (
              <div className={styles[`size-list`]}>
                {variations.toReversed().map((vari, i) => {
                  if (
                    modifiedColors.colorSlugs[props.colorIndex] ===
                    vari.attributes.nodes.filter(
                      (cl) => cl.name === "pa_color"
                    )[0].value
                  ) {
                    return (
                      <SizeItem
                        key={i}
                        size={
                          sizes.terms.nodes[
                            sizes.options.findIndex(
                              (sz) =>
                                sz ===
                                vari.attributes.nodes.filter(
                                  (el) => el.name === "pa_size"
                                )[0].value
                            )
                          ].name
                        }
                        price={0}
                        index={i}
                        forBasket={props.forBasket}
                        setForBasket={props.setForBasket}
                        variations={variations}
                        color={{
                          slug: modifiedColors.colorSlugs[props.colorIndex],
                          name: modifiedColors.colorNames[props.colorIndex],
                          hash: modifiedColors.colorHashes[props.colorIndex],
                        }}
                      />
                    );
                  }
                })}
              </div>
            ) : (
              <div className={styles["loading-wrapper"]}>
                <LoadingAnimation />
              </div>
            )}
          </div>*/}
        </div>
      </div>
    </Fragment>
  );
};

export default ItemSelection;
