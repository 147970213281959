import styles from "./MultiDropdown.module.css";
import arrowDown from "../../assets/Arrow-down.svg";

const MultiDropdown = ({
  options,
  value,
  optionNames = undefined,
  title,
  setValue,
}) => {
  const onValueChangeHandler = (option) => {
    let tempArray = [...value];

    if (!tempArray.some((el) => el.name == option.name)) {
      tempArray.push(option);
    } else {
      tempArray = tempArray.filter((val) => val.name !== option.name);
    }

    setValue(tempArray);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles[`info-wrapper`]}>
        <div>
          {title} {optionNames.length}
        </div>
        <img src={arrowDown} className={styles.arrow} />
      </div>

      <div className={styles[`options-wrapper`]}>
        {options &&
          options.length > 0 &&
          options.map((option, i) => {
            return (
              <label key={i} className={styles.option}>
                <input
                  type="checkbox"
                  onClick={() => onValueChangeHandler(option)}
                  value={option}
                  active={
                    value.some((el) => el.name == option.name)
                      ? "true"
                      : "false"
                  }
                />
                {(optionNames && optionNames[i]) || option.name}
              </label>
            );
          })}
      </div>
    </div>
  );
};

export default MultiDropdown;
