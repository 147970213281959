import styles from "./ItemListMain.module.css";
import ShowItem from "../../UI/ShowItem";
import { useState, useEffect } from "react";
import useGetItems from "../../hooks/use-get-items";

const defaultItemList = [
  -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
  -1, -1, -1, -1, -1, -1,
];

const ItemListMain = ({
  params,
  searchParams,
  currentCategory,
  selectedSorting,
  selectedColors,
  selectedSizes,
  selectedCollections,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    data: itemList,
    pageInfo,
    loading,
    error,
    refetch,
    loadMore,
  } = useGetItems(
    params,
    searchParams,
    selectedSorting,
    selectedColors.map((el) => el.name),
    selectedSizes.map((el) => el.name),
    selectedCollections.map((el) => el.name)
  );

  useEffect(() => {
    if (loading) {
      setIsLoading(true);
    }
  }, [loading]);

  useEffect(() => {
    if (itemList) {
      setIsLoading(false);
    }
  }, [itemList]);

  return (
    <div className={styles[`wrapper`]}>
      {error ? (
        <div className={styles["no-available"]}>Nema dostupnih proizvoda</div>
      ) : (
        <div className={`${styles[`item-grid`]}`}>
          {itemList &&
            itemList.map((item) => {
              return (
                <ShowItem
                  key={item.id}
                  item={item}
                  currentCategory={currentCategory}
                />
              );
            })}
          {isLoading &&
            defaultItemList.map((item, index) => {
              return (
                <ShowItem
                  key={index}
                  item={item}
                  currentCategory={currentCategory}
                />
              );
            })}
        </div>
      )}

      {pageInfo.hasNextPage === true && (
        <div className={styles[`load-more-container`]}>
          <div
            className={styles[`load-more-button`]}
            onClick={() => {
              loadMore({ after: pageInfo.endCursor });
              setIsLoading(true);
            }}
          >
            Učitaj više.
          </div>
        </div>
      )}
    </div>
  );
};
export default ItemListMain;
