import { Fragment, useEffect, useState } from "react";
import SubcategoryItem from "./SubcategoryItem";
import styles from "./SubcategoryList.module.css";
import { useActionData } from "react-router-dom";

const SubcategoryList = ({ categoryCtx, currentCategory }) => {
  const [subcategories, setSubCategories] = useState(
    categoryCtx.categories &&
      currentCategory &&
      categoryCtx.categories.length > 0 &&
      categoryCtx.categories.filter(
        (el) => el.parent && el.parent.node.id == currentCategory.id
      )
  );

  useEffect(() => {
    if (currentCategory.id) {
      setSubCategories(
        categoryCtx.categories.filter(
          (el) => el.parent && el.parent.node.id == currentCategory.id
        )
      );
    }
  }, [currentCategory.id]);

  return (
    <Fragment>
      {subcategories && subcategories.length > 0 && (
        <div className={styles.wrapper}>
          {subcategories.map((sub, i) => {
            return <SubcategoryItem key={sub.id} subcategory={sub} />;
          })}
        </div>
      )}
    </Fragment>
  );
};

export default SubcategoryList;
