import { useQuery, gql } from "@apollo/client";

const useGetItemVariations = (slug) => {
  let GET_ITEM_VARIATIONS = gql`
    query ITEM_VARIATIONS($slug: ID!) {
      product(id: $slug, idType: SLUG) {
        ... on VariableProduct {
          variations(first: 500) {
            nodes {
              attributes {
                nodes {
                  name
                  value
                }
              }
              price(format: FORMATTED)
              id
              image {
                sourceUrl(size: LARGE)
              }
            }
          }
        }
      }
    }
  `;

  const { data, loading, error } = useQuery(GET_ITEM_VARIATIONS, {
    variables: { slug },
  });

  return [data ? data.product.variations.nodes : data, loading, error];
};

export default useGetItemVariations;
