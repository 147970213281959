import { gql, useQuery } from "@apollo/client";
const GET_ALL_CATEGORIES = gql`
  query CategoriesQuery {
    productCategories(first: 100, where: { orderby: NAME }) {
      nodes {
        count
        id
        slug
        description
        name
        display
        menuOrder
        parent {
          node {
            id
          }
        }
        image {
          sourceUrl
        }
      }
    }

    selectedSection: products(
      where: { status: "published", visibility: VISIBLE }
      first: 10
    ) {
      nodes {
        id
        slug
        name
        description(format: RAW)
        shortDescription(format: RENDERED)
        image {
          sourceUrl(size: LARGE)
        }
        ... on VariableProduct {
          galleryImages(first: 200) {
            nodes {
              sourceUrl
            }
          }
          id
          name
          productCategories(first: 5) {
            nodes {
              id
              name
              slug
            }
          }
          attributes(first: 10) {
            nodes {
              ... on GlobalProductAttribute {
                id
                terms(first: 100) {
                  nodes {
                    name
                    id
                  }
                }
                label
              }
              name
              options
              label
            }
          }
        }
      }
    }

    categoryShowcase: products(
      where: { category: "ogrtaci", status: "published", visibility: VISIBLE }
      first: 4
    ) {
      nodes {
        id
        slug
        name
        description(format: RAW)
        shortDescription(format: RENDERED)
        image {
          sourceUrl(size: LARGE)
        }
        ... on VariableProduct {
          galleryImages(first: 200) {
            nodes {
              sourceUrl
            }
          }
          id
          name
          productCategories(first: 5) {
            nodes {
              id
              name
              slug
            }
          }
          attributes(first: 10) {
            nodes {
              ... on GlobalProductAttribute {
                id
                terms(first: 100) {
                  nodes {
                    name
                    id
                  }
                }
                label
              }
              name
              options
              label
            }
          }
        }
      }
    }
  }
`;

const useGetStartupData = () => {
  const { data, loading, error } = useQuery(GET_ALL_CATEGORIES);

  error && console.log(error);
  //data && console.log(data);
  return [data, loading, error];
};

export default useGetStartupData;
