import { useContext, useEffect, useState } from "react";
import CategoryContext from "../store/category-context";
import SavedContext from "../store/saved-context";
import { useQuery, gql } from "@apollo/client";

const useGetItems = (
  category,
  search,
  sorting = undefined,
  selectedColors = [],
  selectedSizes = [],
  selectedCollections = []
) => {
  let GET_ITEMS = gql`
    query ItemsQuery(
      $category: String
      $search: String
      $cursor: String
      $sorting_attribute: ProductsOrderByEnum!
      $sorting_direction: OrderEnum!
      $selectedColors: [String!]
      $selectedSizes: [String!]
      $selectedCollections: [String!]
    ) {
      products(
        where: {
          status: "published"
          visibility: VISIBLE
          orderby: { field: $sorting_attribute, order: $sorting_direction }
          taxonomyFilter: {
            filters: [
              { taxonomy: PA_COLOR, terms: $selectedColors }
              { taxonomy: PA_SIZE, terms: $selectedSizes }
              { taxonomy: PA_KOLEKCIJA, terms: $selectedCollections }
            ]
          }
          search: $search
          category: $category
        }
        first: 25
        after: $cursor
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        nodes {
          id
          slug
          name
          description(format: RAW)
          shortDescription(format: RENDERED)
          image {
            sourceUrl(size: LARGE)
          }
          ... on VariableProduct {
            galleryImages(first: 200) {
              nodes {
                sourceUrl
              }
            }
            id
            name
            productCategories(first: 5) {
              nodes {
                id
                name
                slug
              }
            }
            attributes(first: 10) {
              nodes {
                ... on GlobalProductAttribute {
                  id
                  terms(first: 100) {
                    nodes {
                      name
                      id
                    }
                  }
                  label
                }
                name
                options
                label
              }
            }
          }
        }
      }
    }
  `;

  const { data, error, loading, refetch, fetchMore } = useQuery(GET_ITEMS, {
    variables: {
      category,
      search,
      cursor: null,
      sorting_attribute: sorting ? sorting.attribute : "DATE",
      sorting_direction: sorting ? sorting.direction : "DESC",
      selectedColors: selectedColors,
      selectedSizes: selectedSizes,
      selectedCollections: selectedCollections,
    },
  });

  const loadMore = () => {
    if (data?.products.pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          cursor: data.products.pageInfo.endCursor,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return previousResult;

          return {
            products: {
              ...fetchMoreResult.products,
              nodes: [
                ...previousResult.products.nodes,
                ...fetchMoreResult.products.nodes,
              ],
            },
          };
        },
      });
    }
  };

  return {
    data: data ? data.products.nodes : data,
    pageInfo: data ? data.products.pageInfo : 0,
    loading,
    error,
    refetch,
    loadMore,
  };
};

export default useGetItems;
