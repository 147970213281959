import { useState, Fragment, useEffect, useContext } from "react";
import styles from "./ItemList.module.css";
import { Outlet, useParams } from "react-router-dom";
import ItemListHeader from "./Header/ItemListHeader";
import ItemListMain from "./Main/ItemListMain";
import CategoryContext from "../store/category-context";
import ListActions from "./Main/ListActions";
import SubcategoryList from "./Main/SubcategoryList";
import SearchHeader from "./Header/SearchHeader";

const ItemList = () => {
  const categoryCtx = useContext(CategoryContext);
  let params = useParams().categorySlug;
  let searchParams = useParams().searchTerm;

  const [selectedSorting, setSelectedSorting] = useState({
    name: "Najnoviji",
    attribute: "DATE",
    direction: "DESC",
  });


  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);

  const [currentCategory, setCurrentCategory] = useState(
    params !== undefined ? categoryCtx.getCategory(params) : false
  );

  useEffect(() => {
    if (categoryCtx && params !== undefined) {
      setCurrentCategory(categoryCtx.getCategory(params));
    }

    setCurrentCategory(
      params !== undefined ? categoryCtx.getCategory(params) : false
    );

    setSelectedColors([]);
    setSelectedCollections([]);
    setSelectedSizes([]);
  }, [categoryCtx, params]);

  return (
    <Fragment>
      <Outlet />
      <div className={styles.wrapper}>
        {categoryCtx.categories && (
          <Fragment>
            {searchParams !== undefined && (
              <SearchHeader searchParams={searchParams} />
            )}
            {!searchParams && currentCategory && (
              <>
                <ItemListHeader category={currentCategory} params={params} />
                <SubcategoryList
                  categoryCtx={categoryCtx}
                  currentCategory={currentCategory}
                />
                <div className={styles.count}>
                  {currentCategory &&
                  currentCategory.count &&
                  currentCategory.count !== null
                    ? currentCategory.count
                    : 0}{" "}
                  proizvoda
                </div>
              </>
            )}
            {
              <ListActions
                selectedSorting={selectedSorting}
                setSelectedSorting={setSelectedSorting}
                currentCategory={currentCategory}
                selectedColors={selectedColors}
                setSelectedColors={setSelectedColors}
                selectedCollections={selectedCollections}
                setSelectedCollections={setSelectedCollections}
                selectedSizes={selectedSizes}
                setSelectedSizes={setSelectedSizes}
                params={params}
                searchParams={searchParams === "*" ? "" : searchParams}
              />
            }
            <ItemListMain
              params={params}
              searchParams={searchParams === "*" ? "" : searchParams}
              currentCategory={currentCategory}
              selectedSorting={selectedSorting}
              selectedColors={selectedColors}
              selectedCollections={selectedCollections}
              selectedSizes={selectedSizes}
            />
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default ItemList;
