import { useEffect, useState } from "react";

const useColorManagment = (colors = undefined) => {
  const [colorNames, setColorNames] = useState([]);
  const [colorHashes, setColorHashes] = useState([]);

  const getColorName = () => {
    let temp = [];

    colors.terms.nodes.forEach((color) => {
      temp.push(color.name.substr(0, color.name.lastIndexOf("#")));
    });

    setColorNames(temp);
  };

  const getColorHash = () => {
    let temp = [];

    colors.terms.nodes.forEach((color) => {
      temp.push("#" + color.name.substr(color.name.lastIndexOf("#") + 1));
    });

    setColorHashes(temp);
  };

  useEffect(() => {
    getColorName();
    getColorHash();
  }, []);

  return {
    colorNames,
    colorHashes,
    colorSlugs: colors.options ? colors.options : undefined,
  };
};

export default useColorManagment;
