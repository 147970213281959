import Dropdown from "../../UI/Dropdown";
import MultiDropdown from "../../UI/MultiDropdown";
import ItemList from "../ItemList";
import styles from "./ListActions.module.css";
import { Fragment, useEffect, useState } from "react";
import useColorManagment from "../../hooks/use-color-managment";
import useGetFilters from "../../hooks/use-get-filters";

const ListActions = ({
  currentCategory,
  selectedSorting,
  setSelectedSorting,
  selectedColors,
  setSelectedColors,
  selectedSizes,
  setSelectedSizes,
  selectedCollections,
  setSelectedCollections,
  params,
  searchParams,
}) => {
  const sortingValueList = [
    {
      name: "Najnoviji",
      attribute: "DATE",
      direction: "DESC",
    },
    {
      name: "Cijena od niže",
      attribute: "PRICE",
      direction: "ASC",
    },
    {
      name: "Cijena od više",
      attribute: "PRICE",
      direction: "DESC",
    },

    {
      name: "Najstariji",
      attribute: "DATE",
      direction: "ASC",
    },
  ];

  const { colors, collections, sizes } = useGetFilters(params, searchParams);

  return (
    <div className={styles.wrapper}>
      <MultiDropdown
        title="Kolekcija"
        options={collections}
        optionNames={collections.map((el) => el.name)}
        value={selectedCollections}
        setValue={setSelectedCollections}
      />

      <MultiDropdown
        title="Boja"
        options={colors}
        optionNames={colors.map((color) =>
          color.name.substr(0, color.name.lastIndexOf("#"))
        )}
        value={selectedColors}
        setValue={setSelectedColors}
      />
      {
        <MultiDropdown
          title="Veličina"
          options={sizes}
          optionNames={sizes.map((el) => el.name)}
          value={selectedSizes}
          setValue={setSelectedSizes}
        />
      }
      <Dropdown
        value={selectedSorting}
        setValue={setSelectedSorting}
        options={sortingValueList}
      />
    </div>
  );
};

export default ListActions;
