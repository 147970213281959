import styles from "./ColorSelection.module.css";
import { Fragment, useState } from "react";
import SelectionLabel from "../../UI/SelectionLabel";
import RadioButton from "../../UI/RadioButton";
import useColorManagment from "../../hooks/use-color-managment";

export default function ColorSelection({
  //modifiedColors,
  newSelectedColorIndex,
  setNewSelectedColorIndex,
  selectedColorIndex,
  setColorIndex,
  colorInfo,
  colorIndex,
}) {
  const [hoverIndex, setHoverIndex] = useState(-1);

  const changeColorHandler = (e) => {
    const index = parseInt(e.currentTarget.getAttribute("index"));
    const option = colorInfo.options[index];
    const term = colorInfo.terms.nodes[index].name;

    let temp = [...newSelectedColorIndex];
    const tempIndex = newSelectedColorIndex.findIndex(
      (el) => el.title === colorInfo.title
    );

    temp[tempIndex] = {
      title: colorInfo.title,
      name: colorInfo.name,
      index: index,
      option: option,
      term: term,
    };

    setNewSelectedColorIndex(temp);
    setColorIndex(parseInt(e.currentTarget.getAttribute("index")));
  };

  const modifiedColors = useColorManagment(colorInfo);

  const mouseHoverLeave = () => {
    setHoverIndex(-1);
  };

  return (
    <Fragment>
      <SelectionLabel
        text={`Odaberi ${colorInfo.title}: `}
        additionalText={
          modifiedColors.colorNames &&
          modifiedColors.colorNames[newSelectedColorIndex[colorIndex].index]
        }
        hoverIndex={
          (modifiedColors.colorNames &&
            modifiedColors.colorNames[hoverIndex]) ||
          -1
        }
      />
      <div className={styles[`color-list`]} onMouseLeave={mouseHoverLeave}>
        {modifiedColors.colorHashes &&
          modifiedColors.colorHashes.map((color, i) => {
            return (
              <RadioButton
                active={
                  i === newSelectedColorIndex[colorIndex].index ? true : false
                }
                setHoverIndex={setHoverIndex}
                key={i}
                color={color}
                index={i}
                onClick={changeColorHandler}
              />
            );
          })}
      </div>
    </Fragment>
  );
}
