import { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";

const useGetItem = (slug = "", condition = false) => {
  let GET_ITEM = gql`
    query ITEM($slug: ID!) {
      product(id: $slug, idType: SLUG) {
        id
        slug
        name
        description(format: RAW)
        shortDescription(format: RENDERED)
        image {
          sourceUrl(size: LARGE)
        }
        ... on VariableProduct {
          id
          name
          productCategories(first: 10) {
            nodes {
              id
              name
              slug
            }
          }
          galleryImages(first: 500) {
            nodes {
              sourceUrl
            }
          }
          attributes(first: 15) {
            nodes {
              ... on GlobalProductAttribute {
                id
                terms(first: 100) {
                  nodes {
                    name
                  }
                }
                label
              }
              name
              options
              label
            }
          }
        }
      }
    }
  `;

  const { data, loading, error } = useQuery(GET_ITEM, {
    variables: { slug },
    skip: condition,
  });

  return [data ? data.product : data, loading, error];
};

/*const useGetItem = () => {
  const [item, setItem] = useState(undefined);
  const [itemVariations, setItemVariations] = useState([]);

  const getData = async (itemSlug) => {
    /*try {
      let response = await fetch(
        `https://mediteraneo.eu/wp-json/wc/v3/products?slug=${itemSlug}&consumer_key=ck_a270e588788fe749560568f37f4d9ab9663f48ca&consumer_secret=cs_892dc7028829da5c035079fd9e64da11a9ac9bc4`,
        { mode: "cors" }
      );

      if (response.ok) {
        let value = await response.json();

        getItemVariations(value[0].id);
        setItem(value[0]);
      }
    } catch (error) {
      console.log("Fetch error: ", error.message);
    }
      
  };

  const getItemVariations = async (itemID) => {
    
    try {
      let response = await fetch(
        `https://mediteraneo.eu/wp-json/wc/v3/products/${itemID}/variations?per_page=100&consumer_key=ck_a270e588788fe749560568f37f4d9ab9663f48ca&consumer_secret=cs_892dc7028829da5c035079fd9e64da11a9ac9bc4`
      );

      if (response.ok) {
        let value = await response.json();

        if (value) {
          setItemVariations([...value]);
        }
      }
    } catch (error) {
      console.log("Fetch error: ", error.message);
    }
      
  };

  return [
    item,
    itemVariations,
    setItem,
    setItemVariations,
    getData,
    getItemVariations,
  ];
};
*/
export default useGetItem;
