import { useState, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";

const GET_FILTERS = gql`
  query GetFilters($category: String!, $search: String!, $after: String) {
    products(
      where: { category: $category, search: $search }
      first: 150
      after: $after
    ) {
      nodes {
        ... on VariableProduct {
          allPaColor(first: 100) {
            nodes {
              name
            }
          }
          allPaKolekcija(first: 10) {
            nodes {
              name
            }
          }
          allPaSize(first: 100) {
            nodes {
              name
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

const useGetFilters = (category = "", search = "") => {
  const [colorsMap, setColorsMap] = useState({});
  const [collectionsMap, setCollectionsMap] = useState({});
  const [sizesMap, setSizesMap] = useState({});
  const [fetchingComplete, setFetchingComplete] = useState(false);

  const { data, error, loading, fetchMore } = useQuery(GET_FILTERS, {
    variables: { category, search, after: null },
  });

  useEffect(() => {
    setColorsMap({});
    setCollectionsMap({});
    setSizesMap({});
    setFetchingComplete(false);
  }, [category]);

  useEffect(() => {
    if (!loading && data) {
      const fetchedColors = data.products.nodes.flatMap((product) =>
        product.allPaColor.nodes.map((color) => color.name)
      );
      const fetchedCollections = data.products.nodes.flatMap((product) =>
        product.allPaKolekcija.nodes.map((collection) => collection.name)
      );
      const fetchedSizes = data.products.nodes.flatMap((product) =>
        product.allPaSize.nodes.map((size) => size.name)
      );

      setColorsMap((prevColorsMap) => {
        const updatedMap = { ...prevColorsMap };
        fetchedColors.forEach((color) => {
          updatedMap[color] = (updatedMap[color] || 0) + 1;
        });
        return updatedMap;
      });

      setCollectionsMap((prevCollectionsMap) => {
        const updatedMap = { ...prevCollectionsMap };
        fetchedCollections.forEach((collection) => {
          updatedMap[collection] = (updatedMap[collection] || 0) + 1;
        });
        return updatedMap;
      });

      setSizesMap((prevSizesMap) => {
        const updatedMap = { ...prevSizesMap };
        fetchedSizes.forEach((size) => {
          updatedMap[size] = (updatedMap[size] || 0) + 1;
        });
        return updatedMap;
      });

      if (data.products.pageInfo.hasNextPage) {
        fetchMore({
          variables: {
            category,
            after: data.products.pageInfo.endCursor,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return previousResult;

            return {
              ...fetchMoreResult,
              products: {
                ...fetchMoreResult.products,
                nodes: [
                  ...previousResult.products.nodes,
                  ...fetchMoreResult.products.nodes,
                ],
              },
            };
          },
        });
      } else {
        setFetchingComplete(true);
      }
    }
  }, [data, loading, fetchMore, category, search]);

  return {
    colors: Object.entries(colorsMap).map(([name, count]) => ({ name, count })),
    collections: Object.entries(collectionsMap).map(([name, count]) => ({
      name,
      count,
    })),
    sizes: Object.entries(sizesMap).map(([name, count]) => ({ name, count })),
    loading,
    error,
    fetchingComplete,
  };
};

export default useGetFilters;
