import ShowItem from "../../../UI/ShowItem";
import styles from "./SelectedSection.module.css";
import { useContext, useEffect } from "react";
import LoadingAnimation from "../../../UI/LoadingAnimation";
import useGetItems from "../../../hooks/use-get-items";
import CategoryContext from "../../../store/category-context";

const emptyArrayOfItems = [1, 2, 3, 4, 5, 6, 7, 8];

const SelectedSection = (props) => {
  const { selectedSectionData } = useContext(CategoryContext);
  
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>IZDVOJENI PROIZVODI</div>
      <div className={styles.grid}>
        {selectedSectionData === undefined || selectedSectionData.length === 0
          ? emptyArrayOfItems.map((item, i) => {
              return (
                <div key={i} className={`${styles[i > 3 && `hidden`]}`}>
                  <ShowItem />
                </div>
              );
            })
          : selectedSectionData.map((item, i) => {
              if (i < 8) {
                return (
                  <div key={i} className={`${styles[i > 3 && `hidden`]}`}>
                    <ShowItem load={props.load} key={item.id} item={item} />
                  </div>
                );
              }
            })}
      </div>
    </div>
  );
};

export default SelectedSection;
