import styles from "./CategoryShowcaseSection.module.css";
import { useContext, useEffect, useState } from "react";
import ShowItem from "../../../UI/ShowItem";
import LoadingAnimation from "../../../UI/LoadingAnimation";
import useGetItems from "../../../hooks/use-get-items";
import CategoryContext from "../../../store/category-context";
import NavigationContext from "../../../store/navigation-context";

const emptyArrayOfItems = [1, 2, 3, 4];

const CategoryShowcaseSection = (props) => {
  const { categoryShowcaseData } = useContext(CategoryContext);
  const navCtx = useContext(NavigationContext);

  // loading ručnici default
  const categorySelectionHandler = (id = 253) => {
    navCtx.loadCategory(253);
  };

  return (
    <div className={styles.wrapper}>
      <div onClick={categorySelectionHandler}>
        <div className={styles.title}>Ogrtači</div>
        <div className={styles.lookup}>POGLEDAJ SVE PROIZVODE</div>
      </div>

      <div className={styles.grid}>
        {categoryShowcaseData === undefined || categoryShowcaseData.length === 0
          ? emptyArrayOfItems.map((item, i) => {
              return (
                <div key={i} className={`${styles[i > 3 && `hidden`]}`}>
                  <ShowItem />
                </div>
              );
            })
          : categoryShowcaseData.map((item, i) => {
              return (
                <div key={i} className={`${styles[i > 3 && `hidden`]}`}>
                  <ShowItem load={props.load} key={item.id} item={item} />
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default CategoryShowcaseSection;
