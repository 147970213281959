import { useRef } from "react";
import styles from "./CustomSizeInput.module.css";

export default function CustomSizeInput({ name, min, max, value, setValue }) {
  const timeoutRef = useRef(null);

  const onValueChange = (e) => {
    const tempVal = e.target.value;
    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      if (tempVal > max) {
        setValue(max);
      } else if (tempVal < min) {
        setValue(min);
      }
    }, [1000]);

    setValue(tempVal);
  };

  return (
    <div className={styles.wrapper}>
      <label className={styles[`label`]}>{name}</label>
      <input
        className={styles[`input`]}
        placeholder="0"
        value={value}
        type="number"
        onChange={onValueChange}
        max={max}
        min={min}
      />
      <div className={styles[`descirpiton`]}>max {max} cm</div>
    </div>
  );
}
