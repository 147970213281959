import styles from "./Dropdown.module.css";
import arrowDown from "../../assets/Arrow-down.svg";

const Dropdown = (props) => {
  const value = props.value;

  const onValueChangeHandler = (option) => {
    props.setValue(option);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles[`info-wrapper`]}>
        <div>{value.name}</div>
        <img src={arrowDown} className={styles.arrow} />
      </div>

      <div className={styles[`options-wrapper`]}>
        {props.options.map((option, i) => {
          return (
            <div
              onClick={() => onValueChangeHandler(option)}
              key={i}
              className={`${styles.option} ${
                styles[option.name === value.name && "selected"]
              }`}
              data-option={option}
            >
              {option.name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Dropdown;
