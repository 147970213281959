import { Fragment, useEffect, useState } from "react";
import styles from "./SizeItem.module.css";
import QuantitySelector from "./QuantitySelector";
import CustomSizeInput from "../ItemListPage/FocusItem/CustomSizeInput";

const SizeItem = ({
  size,
  price,
  index,
  color,
  variations,
  forBasket,
  setForBasket,
  custom,
}) => {
  const [quantity, setQuantity] = useState(0);
  const [customWidth, setCustomWidth] = useState(custom && custom.options[0]);
  const [customLength, setCustomLenght] = useState(custom && custom.options[2]);
  const [customHeight, setCustomHeight] = useState(custom && custom.options[4]);

  const setVariation = (value) => {
    let temp = forBasket.filter((el) => el.id !== variations[index].id);

    if (value !== 0)
      temp = [
        ...temp,
        {
          ...variations[index],
          quantity: value,
          attributes: {
            color,
            size,
            customSize:
              size === "custom"
                ? [customWidth, customLength, customHeight]
                : undefined,
          },
        },
      ];

    setQuantity(value);
    setForBasket(temp);
  };

  return (
    <Fragment>
      <div className={styles.wrapper}>
        <div className={styles.info}>
          {size === "custom" ? (
            <div className={styles[`custom-size`]}>
              {custom.options[1] != 0 && (
                <CustomSizeInput
                  min={custom.options[0]}
                  max={custom.options[1]}
                  name="Širina"
                  value={customWidth}
                  setValue={setCustomWidth}
                />
              )}
              {custom.options[3] != 0 && (
                <CustomSizeInput
                  min={custom.options[2]}
                  max={custom.options[3]}
                  name="Dužina"
                  value={customLength}
                  setValue={setCustomLenght}
                />
              )}
              {custom.options[5] != 0 && (
                <CustomSizeInput
                  min={custom.options[4]}
                  max={custom.options[5]}
                  name="Visina"
                  value={customHeight}
                  setValue={setCustomHeight}
                />
              )}
            </div>
          ) : (
            <div className={styles.size}>{size}</div>
          )}

          <QuantitySelector
            index={index}
            setQuantity={setVariation}
            quantity={quantity}
          />

          <div className={styles.price}>{variations[index].price || 0} EUR</div>
        </div>
      </div>
      <div className={styles.divider} />
    </Fragment>
  );
};

export default SizeItem;
