import styles from "./SearchHeader.module.css";

export default function SearchHeader({ searchParams }) {
  return (
    <div className={styles.container}>
      <div className={styles.title}>"{searchParams}"</div>
      <div className={styles.description}>Prikaz pronađenih proizvoda.</div>
    </div>
  );
}
